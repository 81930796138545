export default [
  {
    path: 'testimonial',
    component: () => import('@/views/testimonial/RjBaseTestimonial.js'),
    children: [
      {
        path: '',
        name: 'testimonial',
        component: () => import('@/views/testimonial/RjTestimonial.vue'),
      },
      {
        path: 'yes',
        name: 'positive-testimonial',
        component: () => import('@/views/testimonial/RjPositiveTestimonial.vue'),
      },
      {
        path: 'no',
        name: 'negative-testimonial',
        component: () => import('@/views/testimonial/RjNegativeTestimonial.vue'),
      },
      {
        path: 'completed',
        name: 'completed-testimonial',
        component: () => import('@/views/common/RjCompletedSurvey.vue'),
      },
    ],
  },
];

