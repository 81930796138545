import Vue from 'vue';
import Router from 'vue-router';

import ReviewRoutes from '@/views/review/routes';
import ReferralRoutes from '@/views/referral/routes';
import TestimonialRoutes from '@/views/testimonial/routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/:code',
      name: 'base-survey',
      component: () => import('@/views/RjBaseSurvey.vue'),
      children: [
        ...TestimonialRoutes,
        ...ReviewRoutes,
        ...ReferralRoutes,
      ],
    },
  ],
});

export default router;
