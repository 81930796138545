export default [
  {
    path: 'referral',
    component: () => import('@/views/referral/RjBaseReferral.js'),
    children: [
      {
        path: '',
        name: 'referral',
        component: () => import('@/views/referral/RjReferral.vue'),
      },
      {
        path: 'offer',
        name: 'offer-referral',
        component: () => import('@/views/referral/RjReferralOffer.vue'),
      },
      {
        path: 'no',
        name: 'negative-referral',
        component: () => import('@/views/referral/RjNegativeReferral.vue'),
      },
      {
        path: 'completed',
        name: 'completed-referral',
        component: () => import('@/views/common/RjCompletedSurvey.vue'),
      },
    ],
  },
];
