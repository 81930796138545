export default [
  {
    path: 'review',
    component: () => import('@/views/review/RjBaseReview.js'),
    children: [
      {
        path: '',
        name: 'review',
        component: () => import('@/views/review/RjReview.vue'),
      },
      {
        path: 'share',
        name: 'positive-review',
        component: () => import('@/views/review/RjPositiveReview.vue'),
      },
      {
        path: 'no',
        name: 'negative-review',
        component: () => import('@/views/review/RjNegativeReview.vue'),
      },
      {
        path: 'completed',
        name: 'completed-review',
        component: () => import('@/views/common/RjCompletedSurvey.vue'),
      },
    ],
  },
];
