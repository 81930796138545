import Vue from 'vue';
import Vuesax from 'vuesax';
import VueSocialSharing from 'vue-social-sharing';
import App from '@/App.vue';
import 'vuesax/dist/vuesax.css'; // Vuesax

// Globally Registered Components
import '@/globalComponents';

// Styles: SCSS
import '@/assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from '@/router';

// Feather font icon
import '@/assets/css/iconfont.css';

// Theme Configurations
import { colors, themeConfig } from '../themeConfig';

Vue.use(Vuesax, { theme: { colors }, rtl: themeConfig.rtl });
Vue.use(VueSocialSharing, {
  networks: {
    email: 'mailto:?subject=@t&body=@d%0D%0A@u',
    sms: 'sms:?body=@d%0D%0A@u',
  },
});
Vue.config.productionTip = false;

const app = new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');

export default app;
