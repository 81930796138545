import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import VxCard from '@/components/vx-card/VxCard.vue';
import FeatherIcon from '@/components/FeatherIcon.vue';

Vue.component(VxCard.name, VxCard);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    };
  },
  computesRequired: true,
});
