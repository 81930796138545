<template>
  <div id="app">
    <transition v-bind="{ name: router.transition, mode: 'out-in' }">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { themeConfig } from '@/../themeConfig';

export default {
  name: 'RjApp',

  data() {
    return {
      router: {
        transition: themeConfig.routerTransition || 'none',
      },
    };
  },
};
</script>
